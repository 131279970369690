@import "../../../styles/base/mixins";

.container-info {
  padding: 8rem 0;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;

  .title-section {
    color: #1B1B1B;
    margin-bottom: 80px;

    /* titulo3 */
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    width: 90%;
    margin: 0 auto;

    .card-info {
      width: 300px;
      height: 300px;
      justify-self: center;
      border-radius: 10px;
      background: #FBFDFF;
      padding: 1.5rem;
      transition: all .3s ease-in-out;

      /* sombra 30% */
      box-shadow: 0 0 20px 0 rgba(48, 143, 163, 0.30);

      &:hover {
        .title, .resumen {
          display: none;
        }
        .description {
          display: block;
        }
        transform: scale(1.3);
        background-color: $primary;
        z-index: 1;
        color: $white;
      }

      .title {
        margin-top: 15px;
        color: $black_primary;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .resumen {
        margin-top: 15px;
        color: $black_secondary;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .description {
        display: none;
        margin-top: 15px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}