
.container-features {
  height: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @include tablet {
    height: 1400px;
    grid-template-columns: repeat(auto-fit, minmax(570px, 1fr));
  }

  @include desktop {
    height: auto;
  }

  .mobiles {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 3rem;

    .qr {
      width: 31%;
      height: 62%;
      max-width: 210px;
    }

    .invitation {
      width: 50%;
      margin-left: -20px;
      max-width: 290px;
    }

    @include tablet {
      padding-left: 7rem;

      .qr {
        width: 32%;
        height: 65%;
      }
    }

    @include desktop {
      .qr {
        width: 37%;
        height: 36%;
      }
    }

    @include desktop-wide {
      .qr {
        width: 26%;
        height: 36%;
      }
    }

  }

  .features {
    max-width: 600px;

    .title {
      text-align: center;
      color: #1B1B1B;
      margin-top: 125px;


      /* titulo3 */
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .features-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      margin-bottom: 10rem;

      .feature {
        margin-top: -70px;

        .title {
          color: $black_primary;
          display: flex;
          justify-content: flex-start;
          text-align: justify;


          /* titulo 2 */
          //font-size: 7vw;
          font-size: 25px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          height: 60px;

          @include tablet {
            font-size: 30px;
          }

          //@include desktop {
          //  font-size: 2vw;
          //}

          @include desktop-wide {
            height: 80px;
          }
        }

        .description {
          color: $black_secondary;
          margin-left: 30px;
          text-align: justify;

          /* info2 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          @include tablet {
            font-size: 1.5vw;
          }

          @include desktop {
            font-size: 1.5vw;
          }
        }
      }

    }
  }
}