@import "../../../styles/base/mixins";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  .description {
    //padding-top: 75px;
    padding: 45px 25px 0;

    //padding-left: 50px;

    .title {
      width: 40%;
      opacity: 90%;

      /* Titulo */
      font-size: 65px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .descrip {
      text-align: justify;
      margin-top: 20px;
      color: $black_secondary;

      /* info */
      font-size: 4vw;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @include tablet {
        font-size: 2vw;
      }

      @include desktop {
        margin-top: 50px;
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10%;
    }
  }

  .cel {
    margin-top: 10%;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 55%;
      max-width: 330px;
      //height: 100%;
    }
  }
}