
:root {
  --primary-color: rgb(123, 0, 153);
  --primary-r: 123;
  --primary-g: 0;
  --primary-b: 153;
  --secondary-color: rgb(44, 188, 218);
  --secondary-r: 44;
  --secondary-g: 188;
  --secondary-b: 218;
  --primary-font-color: rgba(0,0,0, .86);
  --background-color: rgba(255, 255, 255, 1);
  --help: rgb(156, 39, 179);
  --info: rgb(49, 75, 255);
  --success: rgb(104, 159, 56);
  --warning: rgb(255, 143, 0);
  --danger: rgb(211, 47, 47);

  --primary-font: Roboto, sans-serif;
  --secondary-font: -apple-system, BlinkMacSystemFont, 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}

$primary: var(--primary-color);
$primary-r: var(--primary-r);
$primary-g: var(--primary-g);
$primary-b: var(--primary-b);
$secondary: var(--secondary-color);
$secondary-r: var(--secondary-r);
$secondary-g: var(--secondary-g);
$secondary-b: var(--secondary-b);

//Help
$help: var(--help);

//Info
$info: var(--info);

//Success
$success: var(--success);

//Warning
$warning: var(--warning);

//Danger
$danger: var(--danger);

// Dark mode
@media (prefers-color-scheme: dark) {
  root {
    --primary-color: rgba(44, 188, 218, 1);
    --secondary-color: rgba(123, 0, 153, 1);
  }
}

// Disabled animation
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none;
    transition: none;
  }
}

// Resets margin, padding
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  vertical-align: baseline;
}

// Images
img, picture, video, iframe, figure {
  max-width: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
  object-position: center center;
}

// Tabs anchor
a {
  display: block;
}

p a {
  display: inline;
}

// List
li {
  list-style-type: none;
}

// Scroll smooth
html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p, span, a, strong, blockquote, i, b, u, em {
  font-size: .85rem;
  font-weight: inherit;
  text-decoration: none;
  color: inherit;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

::selection {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

form, input, textarea, select, button, label {
  font-family: inherit;
  font-size: inherit;
  hyphens: auto;
  background-color: transparent;
  display: block;
  color: inherit;
  appearance: none;
}

// Table
table, tr, td {
  border-collapse: collapse;
  border-spacing: 0;
}

// SVG
svg {
  width: 100%;
  display: block;
  fill: currentColor;
}

// Config web
body {
  //height: 100vh;
  font-size: 100%;
  font-family: var(--primary-font);
  color: var(--primary-font-color);
  background-color: var(--background-color);
  line-height: 1.4em;
  //hyphens: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
