@import "../components/Presentation/PresentationStyles";
@import "../components/Information/InformationStyles";
@import "../components/Features/FeaturesStyles";

.main_container {
  padding: 2rem;

  @include tablet {
    padding: 3rem;
  }

  header {
    height: 64px;
    .logo {
      //padding-left: 30px;
      img  {
        width: 50px;
      }
    }
  }

  .presentation, .info, .features, .contact {
    //height: 75vh;
  }

  .features {
    margin-bottom: 150px;
  }

  .right-corner {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 42%;
    display: flex;
    justify-content: flex-end;
    img {
      width: 100%;
    }
  }

  .frame-left {
    position: absolute;
    z-index: -1;
    top: 900px;
    left: 0;
    img {
      width: 100%;
    }
  }

  .frame-right {
    position: absolute;
    z-index: -1;
    top: 1250px;
    right: 0;
    img {
      width: 100%;
    }
  }

}

footer {
  position: fixed;
  z-index: 0;
  bottom: -40px;
  height: 290px;
  width: 100%;
  background-image: url("../../assets/img/footer.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 1.5rem;

  .content {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    color: $white;
    justify-content: space-between;

    .about {
      width: 55%;

      .title {
        margin-top: 60px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .description {
        margin-top: 10px;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: justify;
        letter-spacing: 1px;

        @include tablet {
          font-size: 13px;
        }
      }
    }

    .contact {
      width: 45%;
      padding-top: 95px;
      div {
        display: flex;
        align-items: center;
        margin: 10px 0 10px 10px;
        letter-spacing: 1px;
        font-size: 8px;

        @include tablet {
          padding-left: 50%;
          margin: 10px 0;
          font-size: 12px;
        }
      }
    }
  }
}


